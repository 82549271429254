import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { LocaleContext } from './Layout'

const LocalizedLink = ({ to, ...props }) => {
  return <Link activeClassName="active" {...props} to={to} />
}

export default LocalizedLink

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}
