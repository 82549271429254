import React from 'react'
import { Helmet } from 'react-helmet'
import TrackVisibility from 'react-on-screen'

import LocalizedLink from '../../LocalizedLink'

const FooterComponent = ({ data, pages }) => {
  const { copyright, description, socials } = data

  return (
    <footer className="footer pb-0">
      <TrackVisibility once>
      <Helmet>
        <script
          async
          defer
          crossOrigin="anonymous"
          src="https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v4.0&appId=161649501159010&autoLogAppEvents=1"
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="logo"><strong>Oxfordon</strong><small>Szkoła języków obcych</small></div>
            <ul className="social list-inline">
              <li className="list-inline-item"><a href="#" target="_blank"><i className="fa fa-facebook" /></a><a href="#" target="_blank"><i className="fa fa-twitter" /></a><a href="#" target="_blank"><i className="fa fa-instagram"></i></a><a href="#" target="_blank"><i className="fa fa-youtube-play"></i></a><a href="#" target="_blank"><i className="fa fa-vimeo"></i></a></li>
            </ul>
            <p>{description.text}</p>
          </div>
          <div className="col-lg-4">
            <h4 className="text-thin">Przejdź do</h4>
            <div className="d-flex flex-wrap">
              <ul className="navigation list-unstyled">
                <li><LocalizedLink to="/">Strona główna </LocalizedLink></li>
                <li><LocalizedLink to="/kursy">Kursy </LocalizedLink></li>
                {pages.map((page, index) => {
                  return (index % 2 === 0 ?
                    <li key={page.id}>
                      {page.uid}
                      <LocalizedLink to={`a/${page.slug}`} title={page.data.title.text}>
                        {page.data.menu_title.text}
                      </LocalizedLink>
                    </li> : null)
                })}
              </ul>
              <ul className="navigation list-unstyled">
                <li><LocalizedLink to="/kontakt">Kontakt </LocalizedLink></li>
                <li><LocalizedLink to="/blog">Blog </LocalizedLink></li>
                {pages.map((page, index) => {
                  return (index % 2 === 1 ?
                    <li key={page.id}>
                      {page.uid}
                      <LocalizedLink to={`a/${page.slug}`} title={page.data.title.text}>
                        {page.data.menu_title.text}
                      </LocalizedLink>
                    </li> : null)
                })}
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="fb-page" data-href="https://www.facebook.com/oxfordon/" data-tabs="timeline" data-width=""
                 data-height="350" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false"
                 data-show-facepile="true">
              <blockquote cite="https://www.facebook.com/oxfordon/" className="fb-xfbml-parse-ignore"><a
                href="https://www.facebook.com/oxfordon/">OXFORDON</a></blockquote>
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <div className="container text-center">
          <p>&copy; {copyright.text}</p>
        </div>
      </div>
      </TrackVisibility>
    </footer>
  )
}

export default FooterComponent
