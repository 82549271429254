import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LocalizedLink from './LocalizedLink'

const Header = () => {
    const data = useStaticQuery(query)

    const pages = data.mainMenuPages.edges
      .map(r => ({ slug: r.node.uid, data: r.node.data }))

    return (
      <header className="header">
        <div className="top-bar d-none d-md-block">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-6">
                <p className="mb-0"><a target="_blank" className="address" rel="noopener noreferrer" href="https://www.google.com/maps/place/Ignacego+Daszy%C5%84skiego+9,+85-790+Bydgoszcz/@53.1559183,18.1460355,17z/data=!3m1!4b1!4m5!3m4!1s0x4703165c88e42315:0x6ae27fe9a05f3c29!8m2!3d53.1559151!4d18.1482242"><i className="icon-placeholder" />85-791 Bydgoszcz, ul. Daszyńskiego 9</a></p>
              </div>
              <div className="col-md-6 text-right">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item"><a href="mailto:zapytaj@oxfordon.pl"><i className="icon-envelope" />zapytaj@oxfordon.pl</a></li>
                  <li className="list-inline-item"><a href="tel:+48523445555"><i className="icon-telephone" />52 344-55-55</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <LocalizedLink to="/" className="navbar-brand">
              <img src="/img/logo-oxfordon.png" alt="Szkoła języków obcych w Bydgoszczy Oxfordon" className="main-logo" />
              <strong>Oxfordon</strong>
              <small>Szkoła języków obcych</small>
            </LocalizedLink>
            <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                    className="navbar-toggler navbar-toggler-right mt-0"><span></span><span></span><span></span>
            </button>
            <div id="navbarSupportedContent" className="collapse navbar-collapse">
              <div className="navbar-nav ml-auto">
                <div className="nav-item"><a href="/" className="nav-link">Home</a></div>
                <div className="nav-item">
                  <LocalizedLink to={`/kursy`} title="Nasze kursy dla Ciebie" className="nav-link">
                    Kursy
                  </LocalizedLink>
                </div>
                {pages.map(page => (
                  <div className="nav-item" key={`main-nav-${page.slug}`}>
                    <LocalizedLink to={`/a/${page.slug}`} title={page.data.title.text} className="nav-link">
                      {page.data.menu_title.text}
                    </LocalizedLink>
                  </div>
                ))}
                <LocalizedLink to={`/blog`} title="Nasz blog" className="nav-link">
                  Blog
                </LocalizedLink>
                <LocalizedLink to={`/kontakt`} title="Kontakt" className="nav-link">
                  Kontakt
                </LocalizedLink>
              </div>
            </div>
          </div>
        </nav>
      </header>
    )
}


const query = graphql`
  query MainMenuQuery {
    mainMenuPages: allPrismicPage(filter: { tags: { eq: "MainMenu" } }, sort: { fields: [data___page_order] }) {
      edges {
        node {
          lang
          id
          uid
          data {
            title {
              text
            }
            menu_title {
              text
            }
          }
        }
      }
    }
  }
`


export default Header
