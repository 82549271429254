import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Footer from '../components/FooterComponent'

const FooterContainer = () => {
  const data = useStaticQuery(query)
  const pages = data.footerPages.edges
    .map(r => ({ id: r.node.id, slug: r.node.uid, data: r.node.data }))

  const footer = data.prismicFooter.data || {}

  return <Footer pages={pages} data={footer} />
}

const query = graphql`
  query FooterQuery {
    footerPages: allPrismicPage(filter: { tags: { eq: "Footer" } }) {
      edges {
        node {
          lang
          id
          uid
          data {
            title {
              text
            }
            menu_title {
              text
            }
          }
        }
      }
    }
    prismicFooter {
          data {
            description {
              html
              text
            }
            copyright {
              text
            }
            socials {
              icon_class {
                text
              }
              title {
                text
              }
              url {
                url
              }
            }
          }
    }
  }
`

export default FooterContainer
