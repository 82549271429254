/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { Global, css } from "@emotion/core"


import '../css/style.default.css'
import '../css/style.blue.css'
import '../css/font.css'

import Header from './Header'
import Footer from './Footer'
import { global } from '../styles'

const globalStyle = css`
  ${global}
`

const Layout = ({ children }) => {

  return (
          <>
            <Global styles={globalStyle} />
            <Header />
            <div className="layout-content">{children}</div>
            <Footer />
          </>
  )
}

export { Layout }

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

