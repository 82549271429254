module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXX-X","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oxfordon - Szkoła Języków Obcych - angielski, niemiecki, francuski ::: Bydgoszcz ::: www.oxfordon.pl","short_name":"Oxfordon - Szkoła Języków Obcych","description":"Oxfordon - Szkoła Języków Obcych - angielski, niemiecki, francuski ::: Bydgoszcz ::: www.oxfordon.pl","start_url":"https://oxfordon.pl","background_color":"#EBEDF2","theme_color":"#1b1a57","display":"standalone","icons":[{"src":"/ico/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/ico/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/ico/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/ico/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
